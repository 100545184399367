import "./App.css";
import { Routes, Route } from "react-router-dom";

import Home from "./Components/Pages/Home";
import World from "./Components/Pages/World";
import WorldStart from "./Components/Pages/WorldStart";
import Activities from "./Components/Pages/Activities";
import Map from "./Components/Pages/Map";
import Login from "./Components/Pages/Login";
import LoginFS from "./Components/Pages/LoginFullScreen";
import { ThemeProvider } from "./Components/ThemeContext/ThemeContext";
import { AuthProvider } from "./Components/auth/AuthContext";
import LoginProtectedRoute from "./Components/LoginProtectedRoute/LoginProtectedRoute";

import Profile from "./Components/Pages/Profile";
import Box from "./Components/Box/Box";
import ForgotPassword from "./Components/Pages/ForgotPassword";
import Signup from "./Components/Pages/Signup";
import Resources from "./Components/Pages/Resources";
import ActivityPage from "./Components/ActivityPage/ActivityPage";
import SupportPage from "./Components/Pages/Support";
import useInactivityTimer from "./Components/InactivityTimer/InactivityTimer";
import ResetPassword from "./Components/Pages/ResetPassword";
import NotFound from "./Components/NotFound/NotFound";
import { useWorldData as useContextWorldData } from "./context/WorldContext";

import React, { useState } from "react";
import About from "./Components/Pages/About";
import Contact from "./Components/Pages/Contact";
import { WorldProvider } from "./context/WorldContext";

function App() {
  const [selectedWorld, setSelectedWorld] = useState(null);

  // Hook call here to initialize the inactivity timer
  useInactivityTimer(1800000); // clear the local storage and log user out in 30 minutes (in milliseconds)

  const handleWorldClick = (worldType) => {
    setSelectedWorld(worldType);
  };

  return (
    <AuthProvider>
      <ThemeProvider>
        <WorldProvider>
          <Routes>
            <Route
              path="/"
              element={
                <Box>
                  <Home />
                </Box>
              }
            />
            <Route
              path="/map"
              element={
                <Box>
                  <Map onWorldClick={handleWorldClick} />
                </Box>
              }
            />
            <Route
              path="/about"
              element={
                <Box>
                  <About />
                </Box>
              }
            />
            <Route
              path="/contact"
              element={
                <Box>
                  <Contact />
                </Box>
              }
            />
            <Route
              path="/login"
              element={
                <Box>
                  <Login />
                </Box>
              }
            />
            <Route
              path="/forgot-password"
              element={
                <Box>
                  <ForgotPassword />
                </Box>
              }
            />
            <Route
              path="/signup"
              element={
                <Box>
                  <Signup />
                </Box>
              }
            />
            <Route path="/loginfs" element={<LoginFS />} />
            <Route
              path="/profile"
              element={
                <LoginProtectedRoute>
                  <Box>
                    <Profile />
                  </Box>
                </LoginProtectedRoute>
              }
            />
            <Route
              path="/world"
              element={<World selectedWorld={selectedWorld} />}
            />
            <Route
              path="/world-start"
              element={
                <Box>
                  <WorldStart selectedWorld={selectedWorld} />
                </Box>
              }
            />
            <Route
              path="/activities"
              element={
                <Box>
                  <Activities />
                </Box>
              }
            />
            <Route
              path="/resources"
              element={
                <LoginProtectedRoute>
                  <Box>
                    <Resources />
                  </Box>
                </LoginProtectedRoute>
              }
            />
            <Route path="/activity/:activityName" element={<ActivityPage />} />
            <Route
              path="/reset-password/:token"
              element={
                <Box>
                  <ResetPassword />
                </Box>
              }
            />
            <Route
              path="/support"
              element={
                <Box>
                  <SupportPage />
                </Box>
              }
            />
            <Route
              path="*"
              element={
                <Box>
                  <NotFound />
                </Box>
              }
            />
          </Routes>
        </WorldProvider>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
