//Aaron - Need to implement the following: API integration:
//loading the user's profile information, and updating the user's profile information.
import TextCard from "../TextCard/TextCard";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Stack,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  useToast,
  Tag,
  TagLabel,
  TagCloseButton,
  Flex,
  Text,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Heading,
  Spinner,
} from "@chakra-ui/react";

import { useAuth } from "../auth/AuthContext";

function Profile() {
  const serverUrl = process.env.REACT_APP_BACKEND_URL;
  const token = localStorage.getItem("token");

  const [firstName, setFirstName] = useState("");
  const [pronouns, setPronouns] = useState([]); //accept multiple pronouns
  //const [pronouns, setPronouns] = useState(''); //accepts single value. comment this line if wanting to accept multiple pronouns
  const [customPronoun, setCustomPronoun] = useState("");
  const [filteredPronouns, setFilteredPronouns] = useState([]);
  const [showPronounSuggestions, setShowPronounSuggestions] = useState(false);

  const [genders, setGenders] = useState([]);
  const [customGender, setCustomGender] = useState("");
  const [filteredGenders, setFilteredGenders] = useState([]);
  const [showGenderSuggestions, setShowGenderSuggestions] = useState(false);

  const [profileInfo, setProfileInfo] = useState(null);
  const [textDisplay, setTextDisplay] = useState(""); //text display for the user's profile information
  const [textDisplayColor, setTextDisplayColor] = useState(null); //text display color for the user's profile information
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState("");
  const [isResetOpen, setIsResetOpen] = useState(false); //modal for reset confirmation

  const toast = useToast();
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const predefinedPronouns = [
    "he/him",
    "she/her",
    "they/them",
    "ze/zir",
    "xe/xem",
  ];
  const predefinedGenders = [
    "Man",
    "Woman",
    "Non-binary",
    "Non-binary Transmasculine",
    "Non-binary Transfeminine",
    "Genderqueer",
    "Agender",
    "Bigender",
    "Genderfluid",
    "Pangender",
    "Two-spirit",
    "Other",
    "Prefer not to say",
  ];
  const username = typeof user === "string" ? user : user?.username;

  const handleInputChange = (
    e,
    setCustomValue,
    setFilteredValues,
    predefinedValues,
    setShowSuggestions
  ) => {
    const input = e.target.value;
    setCustomValue(input);
    if (!input) {
      setFilteredValues([]);
      setShowSuggestions(false);
      return;
    }
    const newFilteredValues = predefinedValues.filter((value) =>
      value.toLowerCase().includes(input.toLowerCase())
    );
    setFilteredValues(newFilteredValues);
    setShowSuggestions(true);
  };

  const handleAddValue = (
    value,
    setValues,
    values,
    setCustomValue,
    setShowSuggestions
  ) => {
    if (value && !values.includes(value)) {
      setValues([...values, value]);
      setCustomValue("");
      setShowSuggestions(false);
    }
  };

  const handleRemoveValue = (value, setValues, values) => {
    setValues(values.filter((v) => v !== value));
  };

  const handlePasswordChange = async () => {
    // Password validation: at least 8 characters, one uppercase, one lowercase
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

    if (!passwordRegex.test(newPassword)) {
      toast({
        title: "Invalid Password",
        description:
          "Password must be at least 8 characters long and include both uppercase and lowercase letters.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      //setTextDisplay("Password must be at least 8 characters long and include both uppercase and lowercase letters.");
      //setbgColor('red');
      return;
    }
    if (newPassword !== confirmPassword) {
      toast({
        title: "Error",
        description: "Passwords do not match.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await fetch(`${serverUrl}/auth/change-password`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ newPassword }),
      });
      console.log(response);

      if (!response.ok) {
        throw new Error("Failed to change password");
      }

      setIsConfirmOpen(false);
      setIsChangingPassword(false);
      setNewPassword("");
      setConfirmPassword("");
      toast({
        title: "Password Changed",
        description:
          "Your password has been successfully changed. Please log in with your new password.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    //logout the user after changing password
    logout();
    //navigate to login page
    navigate("/login");
  };

  // Fetch User Profile
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        if (!username) {
          console.error("User username is not provided");
          return;
        }

        const response = await fetch(`${serverUrl}/userProfiles`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          setTextDisplay(
            "It seems like you don't have a profile yet. \
          Please fill out the form below to create your profile."
          );
        }

        const data = await response.json();
        setProfileInfo(data);
        //console.log(profileInfo);
      } catch (error) {
        setError(error.message);
        setProfileInfo(null);
      } finally {
        setIsLoading(false);
      }
    };

    if (user) {
      fetchProfile();
    }
  }, [user]);

  // Handle Form Submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Construct profile object conditionally
    const profileData = {
      ...(firstName && { name: firstName }), // Only include name if it's not an empty string
      ...(pronouns.length > 0 && { pronoun: pronouns.join(", ") }), // Only include pronouns if there are any
      ...(genders.length > 0 && { gender: genders.join(", ") }), // Only include gender if there are any
    };
    if (pronouns.length > 3 || genders.length > 3) {
      toast({
        title: "Update Failed",
        description: "Please only select up to 3 pronouns and 3 genders.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setTextDisplay("Please only select up to 3 pronouns and 3 genders."); //add toast fallback
      setTextDisplayColor("red");
      return;
    }
    // If nothing to update, return
    if (Object.keys(profileData).length === 0) {
      toast({
        title: "No changes made",
        description: "Please provide information you wish to update.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    // Determine the HTTP method and endpoint based on the existence of a profile
    const method = profileInfo && profileInfo._id ? "PATCH" : "POST";

    const endpoint =
      method === "POST"
        ? `${serverUrl}/userProfiles`
        : `${serverUrl}/userProfiles`;

    try {
      const response = await fetch(endpoint, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(profileData),
      });

      if (!response.ok) {
        throw new Error(
          "Oops! There seems to be a problem with the profile creation/update. \
        Please try again later."
        );
      }

      const data = await response.json();

      // Update local profile state based on the response
      setProfileInfo(data);
      setTextDisplay("Your profile has been updated successfully.");
      setTextDisplayColor("green");
      //console.log(profileInfo);
      toast({
        title: "Profile Updated",
        description: profileInfo
          ? "Your profile has been updated successfully."
          : "Your profile has been created successfully.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Operation Failed",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setTextDisplay(error.message); //add toast fallback
      setTextDisplayColor("red");
    }
  };

  // Function to reset all user progress
  //Backend currently does not have an endpoint to reset all progress, using PATCH to reset all progress
  const handleResetProgress = async () => {
    try {
      // Fetch the current user progress
      const fetchResponse = await fetch(`${serverUrl}/userProgress`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!fetchResponse.ok) {
        throw new Error("Failed to fetch current progress");
      }

      const userProgress = await fetchResponse.json();

      // Make changes to reset the progress
      const updatedProgress = {
        ...userProgress,
        worldProgresses: userProgress.worldProgresses.map((world) => ({
          ...world,
          nextDialogue: null,
          dialogueHistory: [],
          //numberOfDialoguesCompleted: 0,
          status: "notStarted",
        })),
        activityProgresses: userProgress.activityProgresses.map((activity) => ({
          ...activity,
          unlocked: false,
        })),
        lastWorld: null,
        lastDialogue: null,
      };

      // Patch the updated progress back to the server
      const patchResponse = await fetch(`${serverUrl}/userProgress/`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedProgress),
      });

      if (!patchResponse.ok) {
        throw new Error("Failed to reset progress");
      }

      toast({
        title: "Progress Reset",
        description: "All your progress has been reset successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    setIsResetOpen(false); // Close the modal
  };

  const handleDeleteAccount = async () => {
    if (deleteConfirmation !== "permanently delete") {
      toast({
        title: "Deletion Failed",
        description: "Please type 'permanently delete' to confirm.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await fetch(`${serverUrl}/users`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to delete account");
      }

      toast({
        title: "Account Deleted",
        description: "Your account has been successfully deleted.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      logout();
      navigate("/login");
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  if (isLoading)
    return (
      <Center h="50vh">
        <Spinner size="xl" />
      </Center>
    );

  return (
    <Flex direction="column" width="full" p={5} my={20} align="center">
      <Flex direction={["column", "row"]} width="full" justify="space-between">
        <Box
          p={5}
          backgroundColor="white"
          shadow="md"
          borderWidth="1px"
          flex="1"
          borderRadius="md"
          alignItems="center"
          mr={[0, 4]}
        >
          <VStack spacing={4} align="stretch" m={4} maxWidth="sm">
            <Text fontSize="xl" fontWeight="bold">
              Update Profile
            </Text>
            <Text fontSize="sm" color="gray.600">
              Please fill out the form below to update your profile.
            </Text>
            <form onSubmit={handleSubmit}>
              <FormControl>
                <FormLabel>First Name</FormLabel>
                <Input
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Pronouns</FormLabel>
                <Flex wrap="wrap" gap={2}>
                  {pronouns.map((pronoun, index) => (
                    <Tag size="lg" key={index} borderRadius="full">
                      <TagLabel>{pronoun}</TagLabel>
                      <TagCloseButton
                        onClick={() =>
                          handleRemoveValue(pronoun, setPronouns, pronouns)
                        }
                      />
                    </Tag>
                  ))}
                </Flex>
                <Input
                  mt={2}
                  placeholder="Type or select pronouns"
                  value={customPronoun}
                  onChange={(e) =>
                    handleInputChange(
                      e,
                      setCustomPronoun,
                      setFilteredPronouns,
                      predefinedPronouns,
                      setShowPronounSuggestions
                    )
                  }
                  onFocus={() => setShowPronounSuggestions(true)}
                />
                {showPronounSuggestions && (
                  <Stack mt={1} maxH="100px" overflowY="auto">
                    {filteredPronouns.map((pronoun, index) => (
                      <Box
                        key={index}
                        p={2}
                        bg="gray.100"
                        cursor="pointer"
                        _hover={{ bg: "gray.200" }}
                        onClick={() =>
                          handleAddValue(
                            pronoun,
                            setPronouns,
                            pronouns,
                            setCustomPronoun,
                            setShowPronounSuggestions
                          )
                        }
                      >
                        {pronoun}
                      </Box>
                    ))}
                    {filteredPronouns.length === 0 && customPronoun && (
                      <Box
                        p={2}
                        bg="gray.100"
                        cursor="pointer"
                        _hover={{ bg: "gray.200" }}
                        onClick={() =>
                          handleAddValue(
                            customPronoun,
                            setPronouns,
                            pronouns,
                            setCustomPronoun,
                            setShowPronounSuggestions
                          )
                        }
                      >
                        Add "{customPronoun}"
                      </Box>
                    )}
                  </Stack>
                )}
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Gender</FormLabel>
                <Flex wrap="wrap" gap={2}>
                  {genders.map((gender, index) => (
                    <Tag size="lg" key={index} borderRadius="full">
                      <TagLabel>{gender}</TagLabel>
                      <TagCloseButton
                        onClick={() =>
                          handleRemoveValue(gender, setGenders, genders)
                        }
                      />
                    </Tag>
                  ))}
                </Flex>
                <Input
                  mt={2}
                  placeholder="Type or select gender"
                  value={customGender}
                  onChange={(e) =>
                    handleInputChange(
                      e,
                      setCustomGender,
                      setFilteredGenders,
                      predefinedGenders,
                      setShowGenderSuggestions
                    )
                  }
                  onFocus={() => setShowGenderSuggestions(true)}
                />
                {showGenderSuggestions && (
                  <Stack mt={1} maxH="100px" overflowY="auto">
                    {filteredGenders.map((gender, index) => (
                      <Box
                        key={index}
                        p={2}
                        bg="gray.100"
                        cursor="pointer"
                        _hover={{ bg: "gray.200" }}
                        onClick={() =>
                          handleAddValue(
                            gender,
                            setGenders,
                            genders,
                            setCustomGender,
                            setShowGenderSuggestions
                          )
                        }
                      >
                        {gender}
                      </Box>
                    ))}
                    {filteredGenders.length === 0 && customGender && (
                      <Box
                        p={2}
                        bg="gray.100"
                        cursor="pointer"
                        _hover={{ bg: "gray.200" }}
                        onClick={() =>
                          handleAddValue(
                            customGender,
                            setGenders,
                            genders,
                            setCustomGender,
                            setShowGenderSuggestions
                          )
                        }
                      >
                        Add "{customGender}"
                      </Box>
                    )}
                  </Stack>
                )}
              </FormControl>

              <Button mt={4} colorScheme="blue" type="submit">
                Update Profile
              </Button>
            </form>
          </VStack>
        </Box>
        {profileInfo && (
          <Box
            p={5}
            backgroundColor="white"
            shadow="md"
            borderWidth="1px"
            flex="1"
            borderRadius="md"
            alignItems="center"
          >
            <Flex
              direction="column"
              h="100%"
              justifyContent="center"
              paddingX="2em"
            >
              <TextCard text={textDisplay} bgColor={textDisplayColor} />
              <Text fontSize="xl" fontWeight="bold">
                Profile Information
              </Text>
              <Text mt={4}>
                <strong>First Name:</strong> {profileInfo.name}
              </Text>
              <Text mt={2}>
                <strong>Pronouns:</strong> {profileInfo.pronoun}
              </Text>
              <Text mt={2}>
                <strong>Gender:</strong> {profileInfo.gender}
              </Text>
            </Flex>
          </Box>
        )}
      </Flex>

      {/* Buttons for account actions */}
      <Flex direction="column" width="full" p={5} mt={4} align="center">
        <Button
          mt={4}
          colorScheme="teal"
          onClick={() => setIsChangingPassword(!isChangingPassword)}
        >
          {isChangingPassword ? "Cancel Change" : "Change Account Password 🔐"}
        </Button>
        <Button
          mt={4}
          colorScheme="purple"
          onClick={() => setIsResetOpen(true)}
        >
          Reset All Progresses 🌟
        </Button>{" "}
        {/* New button for resetting all progress */}
        <Button mt={4} colorScheme="red" onClick={() => setIsDeleteOpen(true)}>
          Delete Account ⚠️
        </Button>
      </Flex>
      {/* Modal for confirming password change */}
      <Modal isOpen={isConfirmOpen} onClose={() => setIsConfirmOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Password Change</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to change your password?</ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handlePasswordChange}>
              Yes
            </Button>
            <Button variant="ghost" onClick={() => setIsConfirmOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* Modal for resetting all progress */}
      <Modal isOpen={isResetOpen} onClose={() => setIsResetOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Reset All Progress</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to reset all your world and activity progress?
            This action cannot be undone.
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="yellow" mr={3} onClick={handleResetProgress}>
              Reset Progress
            </Button>
            <Button variant="ghost" onClick={() => setIsResetOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* Modal for deleting account */}
      <Modal isOpen={isDeleteOpen} onClose={() => setIsDeleteOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Account Deletion</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={4}>
              Please type 'permanently delete' to confirm the deletion of your
              account. <br />
              <b>CAUTION: This action cannot be undone.</b>
            </Text>
            <Input
              placeholder="permanently delete"
              value={deleteConfirmation}
              fontStyle="italic"
              onChange={(e) => setDeleteConfirmation(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleDeleteAccount}>
              Delete Account
            </Button>
            <Button variant="ghost" onClick={() => setIsDeleteOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default Profile;
